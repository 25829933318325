.App {
  text-align: center;
  height: 100vh;
  overflow-x: hidden;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.topContainer{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tabBar{
  height: 100%;
  background-color: #f0eddd;
  padding-top: 5%;
}
.customBox{
  border-radius: 20px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 15px;
  text-align: center;
}
.custom-icons{
  width: 75%;
  padding: 8px;
  border-radius: 20px;
}
.custom-icons.active{
 background-color: #e6e4bf;
}
.custom-icons:hover{
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.068);
}
.logo{
  width: 40%;
  margin-right: 56%;
}
.menu{
  width: 15%;
  height: 8%;
  padding: 15px;
  margin-right: 1%;
  border-radius: 100px;
  margin-top: 0.5%;
  transition: 0.4s;
}
.menu:hover{
  cursor: pointer;
  background-color: #ffb84d42;
}
.menu.active{
  background-color: #FFB74D;
  /* font-weight: 650; */
}
.customImg{
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
.containerBox{
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 5%;
  max-width: 565px;
  height: 100%;
  text-align: left;
  position: relative;
}
.contactBtn{
  padding: 15px;
  border-radius: 100px;
  background-color: #d6d4b2;
  text-align: center;
  width: 100%;
  transition: 0.4s;
  font-weight: 500;
  font-size: 1.25rem;
  position: absolute;
  bottom: 10%;
}
.contactBtn:hover{
  opacity: 0.7;
  cursor: pointer;
}

.customBtn{
  padding: 12px;
  border-radius: 100px;
  background-color: #d6d4b2;
  text-align: center;
  width: 50%;
  transition: 0.4s;
  font-weight: 500;
  font-size: 1.25rem;
}
.customBtn:hover{
  opacity: 0.7;
  cursor: pointer;
}

.containerBox.two{
  margin-top: 3%;
}
.player{
  height: 100%;
}
.containerBox.three{
  margin-top: 3%;
  max-width: 800px;
  position: relative;
}
.dynamicText{
  width: 85%;
  min-height: 40%;
  font-size: 23px;
  margin-top: 10%;
}
.braisedYellow{
  background-color: #f4f1e4;
}

.title{
  font-size: 2.8rem;
}
.padded{
  padding: 15px;
  margin: auto;
  max-width: 1500px;
  height: 100%;
}
.roundCont{
  border-radius: 35px;
  margin-top: 2% !important;
  /* padding: 15px; */
  margin: auto;
}
.roundSelect{
  border-radius: 100px;
  padding: 25px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.4s;
}
.roundSelect:hover{
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.roundSelect.active{
  background-color: #FFB74D
}
.lf-player-container{
  height: 100%;
}
.mainContainer{
  height: 60%;
  margin: auto;
}
.contactBox{
  border: 2px solid #d6d4b2;
  padding: 20px;
  align-items: center;
}
.contactBox:hover{
  background-color: #d6d4b257;
  cursor: pointer;
}
#contactForm{
  backdrop-filter: blur(10px);
}
.form-control.custom, .form-control.custom > label{
  background-color: #d6d4b257 !important;
  border: none;
}
.form-floating>.form-control-plaintext~label::after, .form-floating>.form-control:focus~label::after, .form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after {
  background-color: transparent;
}
.form-control.custom:focus{
  box-shadow: 0 0 0 0.25rem rgb(225 220 204);
}
.customModal{
  width: 75%;
  height: 85%;
  margin: auto;
  background-color: #f4f1e4;
  padding: 25px;
  overflow: hidden;
  border-radius: 20px;
  transition: 0.4s;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  pointer-events: all;
  position: relative;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.btn-close.custom{
  position: absolute;
  top:3%;
  right: 2%;
}
.react-datepicker-wrapper{
  background-color: #d6d4b257 !important;
  border: none;
  display: block !important;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.customdate{
  background-color: transparent;
  border: none;
  padding: 15px;
}
.loginBtn{
  display: flex;
  background-color: white;
  width: 25%;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 20px;
  transition: 0.4s;
}
.loginBtn:hover{
  cursor: pointer;
  transform: scale(1.2);
  opacity: 0.7;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.infoText:first-child{
  margin-top: 3%;
}
.infoText{
  font-size: 22px;
}
.App-link {
  color: #61dafb;
}
.ornaments{
  opacity: 0.7;
  position: absolute;
  width: 100px;
}
.ornaments.bottomRight{
  bottom: 10%;
  right: -30%;
}
.ornaments.topRight{
  top: -6%;
  right: -30%;
}
.ornaments.bottomLeft{
  bottom: 10%;
  left: -35%;
}
.validate{
  border: 2.5px solid red !important;
}
.ellipsis {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display ellipsis (...) when text overflows */
}
.hamburger{
  display: none;
}
.mobileMenu{
  color: white;
  padding: 15px;
  margin-bottom: 10%;
  border-radius: 100px;
}
.mobileMenu:hover{
  opacity: 0.7;
}
.mobileMenu.active{
  background-color: #FFB74D;
  color: black;
}
.fullscreen{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #2e2f42db;
  backdrop-filter: blur(10px);
  z-index: 200;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
}
.lottie1,.lottie2{
  height: 100%;
}
@media (max-width: 565px) {
  ::-webkit-scrollbar {
    visibility: hidden;
  }
  .topContainer{
    overflow: auto;
  }
  .lottie2{
    height: 75%;
  }
  .lottie1{
    height: 85%;
  }
  .map{
    margin-bottom: 25%;
  }
  .hamburger{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5%;
  }
  .menu{
    display: none;
  }
  .customModal{
    width: 95%;
    overflow: auto;
  }
  .menu {
    width: 80%;
    height: 8%;
    padding: 7px;
    margin-right: 1%;
    border-radius: 100px;
    margin-top: 0.5%;
    transition: 0.4s;
  }
  .logo{
    margin-right: 5px;
    margin-bottom: 0px;
    width: 55px;
    position: relative;
  }
  .title {
    font-size: 1.3rem;
  }
  .infoText{
    font-size: 16px;
  }
  .ornaments{
    opacity: 0.7;
    position: absolute;
    width: 35px;
    z-index: 9999;
  }
  .ornaments.bottomRight{
    bottom: -20%;
    right: 0%;
  }
  .ornaments.topRight{
    top: 0%;
    right: 0%;
  }
  .ornaments.bottomLeft{
    bottom: -20%;
    left: 0%;
  }
  .contactBox {
    padding: 5px;
  }
  .contactText {
    font-size: 15px !important;
  }
  .customBox.adapt{
    height: auto;
  }
  .customBox.descr{
    height: 98%;
    position: relative;
  }
  .row.mainContainer{
    height: auto !important;
  }
  .row.mainContainer.adapt{
    height: 35% !important;
  }
  .map{
    margin-top: 5%;
  }
  .dynamicText {
    width: 85%;
    min-height: 30%;
    font-size: 16px;
    margin-top: 3%;
  }
  .player{
    height: 95%;
  }
  .contactBtn {
    padding: 7px;
    border-radius: 100px;
    background-color: #d6d4b2;
    text-align: center;
    width: 100%;
    transition: 0.4s;
    font-weight: 500;
    font-size: 1.05rem;
    position: absolute;
    bottom: 10%;
  }
  .roundCont{
    margin-top: 45%  !important;
    width: 95%;
    margin-bottom: 25%;
  }
  .roundSelect{
    padding: 15px;
  }
  .roundSelect > h6{
    font-size: 15px;
    margin-left: 5% !important;
  }
  .containerBox{
    margin-top: 5%;
  }
  .mobileMenu {
    color: white;
    padding: 15px;
    margin-bottom: 10%;
    border-radius: 100px;
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media (max-width: 326px) {
  .lottie2{
    height: 80%;
    margin-top: 5%;
  }
  .lottie1{
    height: 100%;
  }
  
  .roundCont {
    margin-top: 70% !important;
    width: 95%;
    margin-bottom: 25%;
  }
 
  .contactBtn {
    bottom: 5%;
    font-size: .95rem;
  }
  .dynamicText {
    width: 85%;
    min-height: 30%;
    font-size: 14.5px;
    margin-top: 3%;
  }
  .customBox.descr{
    height: 100%;
  }
}